import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './home.css';
import  { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Footer from '../components/footer';

function Home() {
  const whatsappNumber = "5547997619410";
  const whatsappMessage = "Olá, vi isso na internet e gostaria de mais informações!";

  useEffect(() => {
    const header = document.querySelector('.App-header');
    if (header) {
      header.style.opacity = 1;
      header.style.transform = 'translateY(0)';
    }
  }, []);

  return (
    <div className="App">
      <div className="App-header">
        <h1 style={{ color: '#043754' }}>
          SITE EM MANUTENÇÃO!
        </h1>
        <p style={{ color: '#086d8b' }}>
          Enquanto isso, fique a vontade paranos enviar uma mensagem pelo WhatsApp ou visitar nossas redes sociais.
        </p>
        <a
          href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FontAwesomeIcon icon={faWhatsapp} /> Enviar mensagem
        </a>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Home;