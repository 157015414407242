// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './home/home';
import Contato from './contato/contato';
import Empresa from './empresa/empresa';
import Servicos from './serviços/serviços';
import Orcamento from './orcamento/orcamento';
import Header from './components/navegate';
// Importe outros componentes de página conforme necessário

function App() {
  return (
    <Router>
      <div className="App">
       <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/empresa" element={<Empresa />} />
          <Route path="/servicos" element={<Servicos />} />
          <Route path="/orcamento" element={<Orcamento/>} />
          {/* Defina mais rotas aqui */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
