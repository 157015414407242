import React from 'react';
import './Header.css'; // Importe os estilos CSS específicos deste componente
import logo from '../icons/logo.png';
import facebook from '../icons/facebook.png';
import instagram from '../icons/instagram.png';
import tiktok from '../icons/tiktok.png';
import whatsapp from '../icons/whatsapp.png';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className='ContainerHeader'>
            <div className='logobox'> 
                <a href="https://www.naturecosystem.com">
                    <img className="logo" src={logo} alt="nature Ecosystem" />
                </a>
            </div>
            <div className=' social-icons-container'>
                <div >
                    {/* Ícones de redes sociais */}
                    <a href="https://www.facebook.com/natureecosystem"  aria-label="icon-facebook">
                    <img className="icons" src={facebook} alt="nature Ecosystem" />
                    </a>
                    <a href="https://www.instagram.com/natureecosystem" aria-label="icon-instagram">
                    <img className="icons" src={instagram} alt="nature Ecosystem" />
                    </a>
                    <a href="https://www.tiktok.com/@nature.ecosystem"  aria-label="icon-tiktok">
                    <img className="icons" src={tiktok} alt="nature Ecosystem" />
                    </a>
                    <a href="https://www.tiktok.com/@nature.ecosystem"  aria-label="icon-tiktok">
                    <img className="icons" src={whatsapp} alt="nature Ecosystem" />
                    </a>
                </div>
            </div>
            {/* Menu de navegação */}
            <nav className='nav'>
                <Link to="/"><span>Home</span></Link>
                <Link to="/empresa"><span>Empresa</span></Link>
                <Link to="/servicos"><span>Serviços</span></Link>
                {/* <Link to="/portfolio"><span>Portfolio</span></Link> */}
                <Link to="/orcamentos"><span>Orçamentos</span></Link>
                <Link to="/contato"><span>Contato</span></Link>
            </nav>
        </div>
    );
};

export default Header;
