// HomePage.js
import React from 'react';
import './Header.css';

function Footer() {
  return (
  <div className='footer'>
    <h4>rodapé</h4>
  </div>
  );
}

export default Footer;
